@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,400;0,600;0,700;0,800;0,900;1,100&family=Barlow:wght@100;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Barlow", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* body {
  overflow-x: hidden;
} */

p {
  color: #2a383c;
  font-size: 18px;
  line-height: 24px;
  font-family: "Barlow", sans-serif;
}

p.small {
  font-size: 16px;
  line-height: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: #2a383c;
}

h5 {
  text-transform: uppercase;
  font-size: 20px;
  color: #8d8d8d;
  margin-bottom: 0;
  margin-top: 0;
  padding-right: 20px;
}

strong {
  font-weight: 600;
}
.MuiFormLabel-root {
  font-family: "Barlow", sans-serif !important;
  font-weight: 500 !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.leftNav {
  width: 120px;
}

.leftNav .MuiButtonGroup-groupedTextVertical:not(:last-child) {
  border-bottom: none;
}

.leftNav h1 {
  background: #c8f6e9;
  margin: 0 0 20px 0;
  padding: 12px 20px;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
}

.leftNav h1 span {
  font-size: 20px;
  font-weight: 600;
}

.leftNav > div {
  width: 120px;
  background: #4c5b5f;
  text-transform: uppercase;
}

.leftNav > div .MuiButtonBase-root {
  text-align: center;
}

.leftNav > div .MuiButtonBase-root span {
  color: #ffffff;
}

.leftNav > div .MuiButtonBase-root:hover span {
  color: #c8f6e9;
  font-weight: 600;
}

.logo {
  opacity: 0.6;
}

.disclaimer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
  background-color: #F1FFFB;
  font-size: 14px;
}

.border {
  border-left: 1px solid rgba(76, 91, 95, 0.26);
  padding-left: 15px;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.metadata-tile {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  background-color: #4c5b5f;
  width: 100%;
  align-items: center;
}

.filtercontainer {
  width: 100% !important;
  margin: 0 !important;
}

.filterLabel {
  color: #c8f6e9;
  font-weight: 600 !important;
  font-size: 12px !important;
  font-family: "Barlow Semi Condensed", sans-serif;
}

.MuiSwitch-thumb {
  color: #5b7e84;
}

.Mui-checked .MuiSwitch-thumb {
  color: #5b7e84;
}

.switchContainer .Mui-checked + .MuiSwitch-track,
.switchContainer .MuiSwitch-track {
  background-color: #c8f6e9 !important;
  opacity: 1 !important;
}

.switchContainer {
  padding: 10px;
  background: #f9f9f9;
  border-radius: 4px;
  margin: 10px 0;
}

.switchContainer .on {
  font-weight: 900;
  color: #5b7e84;
}

a.aboutlink {
  color: #c8f6e9;
  font-size: 12px;
  text-transform: capitalize;
  background: none transparent;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
}

ul {
  list-style: none;
  padding-left: 0;
  margin: 10px 0;
}

.chart-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.chartGrid h2 {
  margin-bottom: 10px;
  margin-top: 0px;
  color: #4c5b5f;
}

.chartGrid p {
  margin-top: 8px;
  font-size: 13px;
  padding: 0 20px;
  line-height: 18px;
}

.chartGrid .MuiIconButton-root {
  padding: 5px 12px;
}

.chartGrid .MuiIconButton-root.MuiSwitch-switchBase {
  padding: 9px;
}

.chartGrid .MuiFormControlLabel-root {
  margin-bottom: 5px;
}

.chartGrid .MuiFormGroup-root * {
  font-size: 16px !important;
}

.chartGrid .MuiFormGroup-root * span {
  line-height: 20px;
}

.chartGrid .MuiFormGroup-root {
  padding: 5px;
  border: 1px solid #f9f9f9;
  width: 100%;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 15px;
  background: #f9f9f9;
  box-sizing: border-box;
}

.sidebarfilter {
  padding-top: 80px;
}

.sidebarfilterTwo {
  padding-top: 0px;
}

@media (min-width: 1024px) {
  .chart {
    box-sizing: border-box;
  }
}

.about {
  background: #f1f0ee;
  padding: 40px 0;
}

.about a {
  font-weight: 700;
  color: #2a383c;
}

.team {
  background: #f1fffb;
  padding: 40px 0;
}

.team ul {
  margin-bottom: 40px;
}

.team ul li {
  padding-bottom: 10px;
  font-size: 18px;
}

.team ul li span.small {
  display: block;
  font-size: 13px;
  font-style: italic;
}
.team ul li a {
  color: #2a383c;
  text-decoration: none;
  font-size: 18px;
  border-bottom: 2px solid #c8f6e9;
}

.team .small a {
  color: #2a383c;
}

.summary {
  padding: 80px 0px;
  background: #f9f9f9;
}

.summary h2 {
  margin-top: 0;
}

.summary table,
.summary .MuiTableCell-root {
  font-family: "Barlow Semi Condensed", sans-serif;
}

.summary .MuiTableCell-head {
  font-weight: 600;
  text-transform: uppercase;
}

.summary ul {
  list-style: disc;
  padding-left: 20px;
  padding-right: 0px;
}

.summary ul li {
  margin-bottom: 12px;
}

.footer img {
  max-width: 100%;
  display: block;
  margin: 20px auto;
  text-align: center;
  opacity: 0.4;
  transition: 0.2s ease-in all;
}

.footer img:hover {
  opacity: 1;
}

.recharts-tooltip-label {
  font-size: 18px !important;
}

.recharts-tooltip-item:nth-child(2n) {
  margin-bottom: 10px !important;
}

.MuiRadio-colorSecondary.Mui-checked:not(.Mui-disabled),
.MuiCheckbox-colorSecondary.Mui-checked:not(.Mui-disabled) {
  color:rgb(76, 91, 95) !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 960px) {
  .team,
  .about {
    padding: 40px 24px;
  }
  .footer img {
    margin: 10px auto !important;
    display: block !important;
  }
  .summary {
    padding: 40px 20px 80px;
  }
  .leftNav {
    display: none;
  }
  .leftNav + main {
    width: auto !important;
    margin-left: 0 !important;
  }
  .disclaimer {
    padding: 12px 12px 3px;
    display: block;
  }
  .disclaimer p {
    font-size: 14px;
    line-height: 18px;
  }
  .innerDisclaimer {
    width: 100%;
    margin-top: 0;
    display: block;
  }
  .border {
    border: 0;
    padding: 0;
  }
}