@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,400;0,600;0,700;0,800;0,900;1,100&family=Barlow:wght@100;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Barlow", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-x: hidden;
  margin: 0;
}

p {
  color: #2a383c;
  font-size: 18px;
  line-height: 24px;
  font-family: "Barlow", sans-serif;
}

p.small {
  font-size: 16px;
  line-height: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2a383c;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}

h5 {
  text-transform: uppercase;
  font-size: 20px;
  color: #8d8d8d;
  margin-bottom: 0;
  margin-top: 0;
  padding-right: 20px;
}

/* AppBar & Nav */

.MuiButtonGroup-grouped {
  min-width: 40px;
  color: #c8f6e9;
}

#menuGroup, #menuGroup-primo {
  display: block;
  text-align: right;
}

#menuGroup a, #menuGroup-primo a {
  border-radius: 0 !important;
}

#menuGroup-primo a span {
  color: rgb(200, 246, 233);
  text-transform: uppercase;
  /* border: 1px solid rgb(200, 246, 233);
  border-radius: 4px;
  padding: 5px 10px; */
}

#menuGroup-primo a#primo span {
  border: 1px solid rgb(200, 246, 233);
  border-radius: 4px;
  padding: 5px 10px;
}

#menuGroup a span {
  color: #ecfdf6 !important;
  border-bottom: 2px solid #7CE4BB !important;
}

#menuGroup a span + span,
#menuGroup-primo a span + span {
  border: 0 !important;
}

/* Intro Panel */

#intro {
  background: #efefef;
  padding: 20px;
  border-radius: 8px;
}

#intro h1 {
  text-transform: none;
  font-size: 28px;
  line-height: 34px;
}

#intro p {
  font-size: 16px;
  line-height: 20px;
}

#intro a.MuiButton-containedPrimary {
  background-color: #4c5b5f;
  margin-bottom: 10px;
  margin-top: 20px;
}

#intro a.MuiButton-containedPrimary:hover {
  box-shadow: 0 0 10px black;
}

#intro .MuiButton-contained.Mui-disabled {
  margin-bottom: 10px;
  margin-top: 20px;
  opacity: 0.5;
}

#intro a.MuiButton-containedPrimary .MuiButton-label {
  color: #ffffff;
}

#intro a.MuiButton-containedPrimary svg {
  fill: #ffffff;
}

/* Maps */

#maps img {
  max-width: 100%;
  mix-blend-mode: multiply;
}

strong {
  font-weight: 600;
}
.MuiFormLabel-root {
  font-family: "Barlow", sans-serif !important;
  font-weight: 500 !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logo {
  opacity: 0.6;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.metadata-tile {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  background-color: #4c5b5f;
  width: 100%;
  align-items: center;
}

/* Sticky Filter */

.sticky-inner-wrapper {
  background: rgba(236, 253, 246, 0.9);
  padding-bottom: 20px;
  width: 100%;
}

.filtercontainer {
  width: 100% !important;
  margin: 0 !important;
}

.filterLabel {
  color: #c8f6e9;
  font-weight: 600 !important;
  font-size: 12px !important;
  font-family: "Barlow Semi Condensed", sans-serif;
}

.MuiChip-label {
  color: rgb(236, 253, 246);
}

.MuiChip-root.MuiAutocomplete-tag {
  background-color: #4c5b5f !important;
}

#infdthproj .sticky-inner-wrapper {
  background: #F1FFFB !important;
  padding: 20px;
  width: 100%;
  z-index: 999 !important;
}

/* About Panel */

#about ul {
  margin-left: 40px;
}

#about ul li {
  padding-bottom: 20px;
}

a.aboutlink {
  color: #c8f6e9;
  font-size: 12px;
  text-transform: capitalize;
  background: none transparent;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
}

ul {
  list-style: none;
  padding-left: 0;
  margin: 10px 0;
}

div#charts h4 {
  font-weight: 500;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.MuiSwitch-thumb {
  color: #5b7e84;
}

.Mui-checked .MuiSwitch-thumb {
  color: #5b7e84;
}

.Mui-checked + .MuiSwitch-track,
.MuiSwitch-track {
  background-color: #c8f6e9 !important;
  opacity: 1 !important;
}

.on {
  font-weight: 700;
}

svg.MuiSvgIcon-root.MuiChip-deleteIcon {
  fill: #c8f6e9;
}

.chart-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.chartGrid h2 {
  margin-bottom: 10px;
  margin-top: 0;
  color: #4c5b5f;
  font-weight: 400;
  font-size: 24px;
}

.chartGrid p {
  margin-top: 8px;
  font-size: 13px;
  padding: 0 20px;
  line-height: 18px;
}

#contacts.chartGrid h2,
#mobility.chartGrid h2,
#commutes.chartGrid h2 {
  padding-left: 20px;
  margin-top: 0;
}

@media (min-width: 1024px) {
  .chart {
    box-sizing: border-box;
  }
}

.about {
  background: #f1f0ee;
  padding: 40px 0;
}

.about a {
  font-weight: 700;
  color: #2a383c;
}

/* Team Panel */

.team {
  background: #f1fffb;
  padding: 40px 0;
}

.team ul {
  margin-bottom: 40px;
}

.team ul li {
  padding-bottom: 10px;
  font-size: 18px;
}

.team ul li span.small {
  display: block;
  font-size: 13px;
  font-style: italic;
}
.team ul li a {
  color: #2a383c;
  text-decoration: none;
  font-size: 18px;
  border-bottom: 2px solid #c8f6e9;
}

.team .small a {
  color: #2a383c;
}

/* Footer */

.footer img {
  max-width: 100%;
  display: block;
  margin: 20px auto;
  text-align: center;
  opacity: 0.4;
  transition: 0.2s ease-in all;
}

.footer img:hover {
  opacity: 1;
}

.recharts-tooltip-label {
  font-size: 18px !important;
  font-weight: 700;
  margin-left: -20px !important;
  margin-bottom: 10px !important;
}


.fade {
  opacity: 0.5;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 960px) {
  .team,
  .about,
  #data {
    padding: 40px 24px;
  }
  .MuiToolbar-root {
    display: block !important;
  }
  .footer img {
    margin: 10px auto !important;
    display: block !important;
  }
  .disclaimer {
    padding: 12px 12px 3px;
    display: block;
  }
  .disclaimer p {
    font-size: 14px;
    line-height: 18px;
  }
  .innerDisclaimer {
    width: 100%;
    margin-top: 0;
    display: block;
  }
  .border {
    border: 0;
    padding: 0;
  }
}

@media (max-width: 600px) {
  div#charts > div {
    flex-direction: column-reverse !important;
  }
}

.mapPanel h3 {
  margin: 0;
  font-weight: 600;
  text-transform: capitalize;
}

.mapPanel p {
  font-size: 16px;
  margin: 0;
}
